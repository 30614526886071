/* You can add global styles to this file, and also import other style files */

$header-bg-color: #404040;
$header-bg-color-secondary: lightgray;
$header-txt-color: white;

.placeholder-img {
  width: 40px;
}

.dx-datagrid-table {
  .dx-header-row {
    background: $header-bg-color;
    color: $header-txt-color;
  }

  .dx-datagrid-filter-row {
    background: $header-bg-color-secondary;
  }
}

.dx-datagrid-headers .dx-datagrid-table .dx-row>td .dx-sort,
.dx-datagrid-headers .dx-datagrid-table .dx-row>td .dx-sort-indicator,
.dx-datagrid-headers .dx-datagrid-table .dx-row>td:hover .dx-datagrid-text-content {
  color: $header-txt-color!important;
}

body:not(.pos-screen) {
  .dx-popup-title.dx-toolbar {
    background: $header-bg-color;
    color: $header-txt-color;
    .dx-icon {
      color: $header-txt-color;
    }
  }
}



.dx-row {
  dx-button {
    &.dx-button-normal {
      cursor: default!important;
      -webkit-box-shadow: none;
      box-shadow: none;
      background-color: rgba(0, 0, 0, .08)!important;
    }
    &.dx-button-success {
      cursor: default!important;
    }
  }
}

.dx-group-row {
  background-color: #fcecdc!important;
}